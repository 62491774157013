import React, { Component} from "react";
import { withRouter, Redirect} from "react-router-dom";
import { clear, set, get } from "services/storage";
import { school_classes_api, school_classes_store_api, school_activities_classes_api, prof_classes_api, 
  prof_activities_classes_api, schoolStudents, missing_students_api_get, profStudents, schools_scholar_years,
  get_list_schools_sub_groups, fields_export_api_get, textbooks_subjects_export_api_get,
  school_professors_list_api, school_professors_list_api2, list_semesters_api, check_school_api, log_js_error, sanady_api_get,
  get_koolskools_levels, school_settings_payment_api_get, transport_api_get, device_token_api, 
  school_mailings_api_list, school_mailings_api_store, absencesApi, absences_post_api, 
  behaviours_list_api, behaviours_list_api_post, school_permissions_api_list, reports_list_api, get_instanceID_info,
  professor_professors_list_api } from "services/cms/api";
  import { listSchoolsAction, scholarYearsAction } from "services/cms/actions";
import {levelGroups} from "services/api"
import axios from "axios";
import moment from "moment";
import {messaging} from 'init_fcm'
import { connect } from "react-redux";
import Permission from 'components/Permission'
import { init_database } from "../services/database/schools";
import localforage from 'localforage';


const instance = axios.create({ baseURL: process.env.REACT_APP_URL_CMS });
const base_url = process.env.REACT_APP_URL_CMS;
class LayoutSchoolSystem extends Component {
  constructor(props) {
    super(props);
    this.user = get("session_user");
    this.school = get("school");
    this.is_program_system = this.school && this.school.project_id || this.user && (this.user.profile.is_program_system || this.user.profile.is_sanady_assistant);
    this.program = get("program");
    this.levels = get('levels') || [];
    this.school_levels_for_assistant = get('school_levels_for_assistant') || [];
    this.is_sanady_view = this.school && this.school.project_id || this.user && (this.user.profile.is_program_system || this.user.profile.is_sanady_assistant);
    this.ws = false
    var school_ws = ""; 
    this.show_students_to_change_levels_notifs = (this.user.profile.is_program_system || this.user.profile.is_sanadi_coordinator);
    var school_administrators_ws = ""; 
    this.permission = new Permission();
    this.permission.setCurrentSection('reporting');
    Array.prototype.unique = function() {
      var a = this.concat();
      for(var i=0; i<a.length; ++i) {
          for(var j=i+1; j<a.length; ++j) {
              if(a[i].id === a[j].id)
                  a.splice(j--, 1);
          }
      }
      return a;
    }; 
    Array.prototype.unique_item = function() {
      var a = this.concat();
      for(var i=0; i<a.length; ++i) {
          for(var j=i+1; j<a.length; ++j) {
              if(a[i] === a[j])
                  a.splice(j--, 1);
          }
      }
      return a;
    }; 
    // if(props.match.params.school_id && !this.user.profile.is_formator){
    //   school_ws = `&school_id=${props.match.params.school_id}`;
    //   if(this.school && this.school.administrators_usernames && this.school.user_can_view_admin_conversations && this.school.administrators_usernames.indexOf(this.user.profile.username) == -1){
    //     school_administrators_ws = `&administrators_usernames=${this.school.administrators_usernames}`;
    //   }
    // }
    
    if(/*this.permission.can('ca_table') && */!this.user.profile.is_formator && (!this.school || !this.school.is_parent)){
      if(this.school && !global.ws_notification){
        global.ws_notification = new WebSocket(
          `${process.env.REACT_APP_URL_MESSAGING}/ws/users/?user_id=${this.user.profile.user_id}&group_id=${this.school.group_id}`
        );
      }
    }

    // this.ws_notifier = new WebSocket(
    //   `${process.env.REACT_APP_URL_WS_CMS}/ws/notifications/?username=${
    //       this.user.profile.username
    //   }${school_ws}${school_administrators_ws}`
    // );


    if(!get("koolskools_levels") && !this.user.profile.is_formator){
      get_koolskools_levels()
    }
    if(!get("level_groups") || this.user && (this.user.is_tarl_professor || (this.user.profile.is_program_system || this.user.profile.is_sanady_assistant)) && get("level_groups").filter(lg => lg.id == 5).length == 0){
      levelGroups()
    }
    else if(!(this.user && (this.user.is_tarl_professor || (this.user.profile.is_program_system || this.user.profile.is_sanady_assistant))) && (get("level_groups") || []).filter(lg => lg.id == 5).length > 0){
      levelGroups()
    }
    else{
      var levels_groups = get("level_groups") || [];
      if(this.user && (this.user.is_tarl_professor || (this.user.profile.is_program_system || this.user.profile.is_sanady_assistant))){
        let tarl_cycle = undefined;
        levels_groups = levels_groups.map(lg => {
          if(lg.is_tarl){
            lg.name = "Primaire";
            tarl_cycle = lg;
          }
          return lg;
        }).filter(lg => lg.num > "1" && !lg.is_tarl);
        if(tarl_cycle){
          levels_groups.unshift(tarl_cycle);
        }
      }
      else{
        levels_groups = levels_groups.filter(lg => !lg.is_tarl);
      }
      set("level_groups", levels_groups);
    }
    if((get('scholar_years') || []).length == 0){
      this.props
      .dispatch(scholarYearsAction())
      .then(data => {
        const years = data.payload.years;
        set("scholar_years", years);
      })
      .catch(error => console.log("errors", error));
    }
    if(this.school){
      school_permissions_api_list({schools_ids: this.school.id + "", action: "get_specific_permissions", section: "professors", ability: "update_level_classe_for_all_cycles"}, true).then(res => {
        this.can_update_level_classe_for_all_cycles = ((((res.schools_permissions || {})[this.school.id] || [])[0] || {}).abilities || []).length > 0;
      });
    }

    this.professors__ = [];
    this.state = {
      menuGlobal: false,
      data_loaded:false,
      classes: [],
      assistant_other_classes: [],
      activities_classes: [],
      students: [],
      professors: [],
      seances:[],
      parents: [],
      semesters: [],
      school_all_fields_export: {},
      school_predefined_fields_export: {},
      school_all_subjects_export: {},
      school_id: props.match.params.school_id,
      scholar_year_id: props.match.params.scholar_year_id,
      classe_id: props.match.params.classe_id,
      activity_classe_id: props.match.params.activity_classe_id,
      scholar_years: [],
      showExistantsButton: false,
      ws: this.ws,
      is_closed_payments: false,
      vehicules_need_oil_change_ids: [],
      absent_professors_today_ids: [],
      new_registred_professors_ids: [],
      students_to_change_levels_ids: [],
      missing_students: [],
      messages_notif_ids: [],
      valid_messages_notif_ids: [],
      absences_notifications_ids: [],
      incidents_notifications_ids: [],
      rdv_reports_notifications_ids: [],
      students_notifications_ids: [],
      programs_projects_schools: {},
      programs_regions: {},
      programs_zones: {},
      programs_companies: {},
    };

    window.addEventListener('store_levels',(e) => {
      this.levels = get("levels") || [];
      this.update_professors();
    }, false);

    if(this.school){
      this.getSchoolAbsNotifs(props.match.params.school_id, props.match.params.scholar_year_id);
      this.getSchoolIncidentsNotifs(props.match.params.school_id, props.match.params.scholar_year_id);
      this.getSchoolRDVReportsNotifs(props.match.params.school_id, props.match.params.scholar_year_id);
    }

    if(props.school_account){
      if(!this.user || !this.user.profile.is_school_admin) 
        return <Redirect to="/home/school" />;
      if(/([À-ú]|\s)/.test(this.user.profile.username)){
        var lev = get("level_groups");
        var current_language = get("current_language");
        clear();
        localforage.clear();
        if(global.ws_notification)
          global.ws_notification.close();
        set("level_groups", lev);
        set("current_language", current_language);
        return <Redirect to="/home/school" />;
      }
      if((this.user.profile.is_program_system || this.user.profile.is_sanady_assistant) && !get('project')){
        if(this.props.path != "/school/program/list")
          return <Redirect to="/school/program/list" />;
      }
        
        
      else if(this.props.path != "/school/list" && !(this.user.profile.is_program_system || this.user.profile.is_sanady_assistant)){
        if(this.user.profile.admin_schools && this.user.profile.admin_schools.filter(sc => sc.id == parseInt(this.props.computedMatch.params.school_id)).length == 0)
          return <Redirect to="/school/list" />;
        check_school_api({school: this.props.computedMatch.params.school_id}).then(res => {
          // school_permissions_api_list({school_id: this.props.computedMatch.params.school_id});
        }).catch(err => {
          this.props.history.push("/school/list")
        })
      }
    }
    else{
      if(!this.user || ['pr','pr_pa'].indexOf(this.user.account_type) < 0  || ((this.user.schools || this.user.profile && this.user.profile.schools || []).length == 0 && !(this.user.profile && this.user.profile.had_sanady_school_from_last_year)))
        return <Redirect to="/home/professor" />;
      if(/([À-ú]|\s)/.test(this.user.profile.username)){
        var lev = get("level_groups");
        var current_language = get("current_language");
        clear();
        localforage.clear();
        if(global.ws_notification)
          global.ws_notification.close();
        set("level_groups", lev);
        set("current_language", current_language);
        return <Redirect to="/home/professor" />;
      }
    }
    if(this.is_program_system && this.program){
      this.getProgramsProjectsSchools();
      this.handleGetRegions();
      this.handleGetZones();
      this.handleGetCompanies();
    }
  }

  getProgramsProjectsSchools = () => {
    var data = {
      action: "get_programs_projects_schools",
    };
    data.scholar_year_id = this.props.match.params.scholar_year_id;
    sanady_api_get(data).then(res => {
      if(res.success){
        this.setState({programs_projects_schools: res.programs_projects_schools || {}});
      }

    }).catch(err => {
      console.log(err);
    })
  }

  handleGetRegions = () => {
    var data = {
      action: "get_regions",
    };
    sanady_api_get(data).then(res => {
      this.setState({
        programs_regions: res.programs_regions || {},
      });
    });

  }
  handleGetZones = () => {
    var data = {
      action: "get_zones",
    };
    sanady_api_get(data).then(res => {
      this.setState({
        programs_zones: res.programs_zones || {},
      });
    });

  }
  handleGetCompanies = () => {
    var data = {
      action: "get_companies",
    };
    sanady_api_get(data).then(res => {
      this.setState({
        programs_companies: res.programs_companies || {},
      });
    });

  }

  componentWillReceiveProps(props){
    this.setState({
      school_id: props.match.params.school_id,
      scholar_year_id: props.match.params.scholar_year_id,
      classe_id: props.match.params.classe_id,
      activity_classe_id: props.match.params.activity_classe_id,
    })
  }

  getSchoolAbsNotifs = (school_id, scholar_year_id) => {
    var data = {
      "action": "get_absences_notifications_count",
      "school_id": school_id || this.state.school_id,
      "scholar_year": scholar_year_id || this.state.scholar_year_id,
    };
    absencesApi(data).then(res => {
      this.setState({
        absences_notifications_ids: res.absences_notifications_ids,
      });
    });
  }
  getSchoolIncidentsNotifs = (school_id, scholar_year_id) => {
    var data = {
      "action": "get_incidents_notifications_count",
      "school_id": school_id || this.state.school_id,
      "scholar_year": scholar_year_id || this.state.scholar_year_id,
    };
    behaviours_list_api(data).then(res => {
      this.setState({
        incidents_notifications_ids: res.incidents_notifications_ids,
        students_notifications_ids: this.state.students_notifications_ids.concat(res.incidents_notifications_ids).unique_item(),
      });
    });
  }
  getSchoolRDVReportsNotifs = (school_id, scholar_year_id) => {
    var data = {
      "action": "get_rdv_reports_notifications_count",
      "school_id": school_id || this.state.school_id,
      "scholar_year": scholar_year_id || this.state.scholar_year_id,
    };
    reports_list_api(data).then(res => {
      this.setState({
        rdv_reports_notifications_ids: res.rdv_reports_notifications_ids,
        students_notifications_ids: this.state.students_notifications_ids.concat(res.rdv_reports_notifications_ids).unique_item(),
      });
    });
  }

  componentDidCatch(error, info) {
    // console.log(error)
    // console.log(error.stack)
    // Display fallback UI
    this.props.history.push('/ErrorPage');
    // You can also log the error to an error reporting service
    var data={
      // error_msg: error.message,
      error_stack: error.stack,
      // error_info: info.componentStack
    }
    log_js_error(data);
  }

  get_local_levels = () => {
    return this.school_levels_for_assistant.length > 0 ? this.school_levels_for_assistant.filter(l => this.levels.filter(l_ => l_.level_id == l.level_id)[0] || l) : this.levels;
  }

  componentDidMount(){
    this.school = get("school");
    this.schools = this.user.profile.admin_schools.filter(sc => (this.school && this.school.is_parent) || !sc.is_parent);
    if((this.permission.can('ca_table') || this.permission.can('unpaid_list')) && this.schools.length > 0){

      if(this.user.profile.is_assistant)
        school_permissions_api_list({schools_ids: this.schools.map(s => s.id).join(";;"), action: "payments_permissions"}, true).then(res => {
          let schools_permission_reporting_ids = Object.keys(res.schools_permissions).filter(sc_key => res.schools_permissions[sc_key].findIndex(sp => sp.section.name == "reporting" && sp.abilities.findIndex(ab => ab.name == 'ca_table' || ab.name == 'unpaid_list') >= 0) >=0).map(sc_key => parseInt(sc_key));
          if(schools_permission_reporting_ids.length > 0)
            init_database(this.schools.filter(sc => schools_permission_reporting_ids.indexOf(sc.id) >= 0), true);
        });
      else
        init_database(this.schools, true);
    }

    if(!this.user.profile.is_formator && (!this.school || !this.school.is_parent)){
      this.getJournals();
      if(this.school && this.can_update_level_classe_for_all_cycles !== false && this.can_update_level_classe_for_all_cycles != true){
        school_permissions_api_list({schools_ids: this.school.id + "", action: "get_specific_permissions", section: "professors", ability: "update_level_classe_for_all_cycles"}, true).then(res => {
          this.can_update_level_classe_for_all_cycles = ((((res.schools_permissions || {})[this.school.id] || [])[0] || {}).abilities || []).length > 0;
          this.getClassesList();
        });
      }
      else{
        this.getClassesList();
      }
      this.getStudentsList();
      this.getAbsentProfessorsTodayIdsList();
      // this.getProfessorsList();
      this.getActivitiesClassesList();
      this.getVehiculesNeedOilChangeCount();
      this.getNotifMail();
      if(this.state.school_id)
        this.getScholarYears();
      if(this.school && this.school.enable_mailing_validation)
        this.getNotifValidMail();
    }
    if(this.is_sanady_view){
      this.getNewRegisteredProfessorsIdsList();
      if(this.show_students_to_change_levels_notifs){
        this.refreshStudentsToChangeLevels();
      }
      this.refreshStudentsMissings();
    }
    if(this.school && this.school.is_parent){
      if(this.can_update_level_classe_for_all_cycles !== false && this.can_update_level_classe_for_all_cycles != true){
        school_permissions_api_list({schools_ids: this.school.id + "", action: "get_specific_permissions", section: "professors", ability: "update_level_classe_for_all_cycles"}, true).then(res => {
          this.can_update_level_classe_for_all_cycles = ((((res.schools_permissions || {})[this.school.id] || [])[0] || {}).abilities || []).length > 0;
          this.getClassesList();
        });
      }
      else{
        this.getClassesList();
      }
      this.getSubGroupsList();
    }
    if(this.school){
      var data = {
        action: "get_semesters",
        school_id: this.state.school_id
      };
      list_semesters_api(data).then(res => {
        this.setState({
          semesters: res.semesters
        })
      })
      data = {
        action: "get_all_school_fields_export",
        school_id: this.state.school_id
      };
      fields_export_api_get(data).then(res => {
        this.setState({
          school_predefined_fields_export: res.school_predefined_fields_export || {},
          school_all_fields_export: res.school_all_fields_export || {}
        })
      })
      data = {
        action: "get_all_school_subjects_export",
        school_id: this.state.school_id
      };
      textbooks_subjects_export_api_get(data).then(res => {
        this.setState({
          school_all_subjects_export: res.school_all_subjects_export || {}
        })
      })
    }
    window.addEventListener('new_notification',(e) => {
      debugger
    }, false);
    if(messaging && !get('device_token')){
      messaging
      .requestPermission()
      .then(async () => {
        const token = await messaging.getToken();
        set('device_token', token);
        this.refresh_token(token)
      })
    }
      
      // this.refreshStats({selected_period:0,selected_subject:''});
  }

  refresh_token = token => {
    var data = {
      device_token: token
    };
    device_token_api(data)
  }

  getVehiculesNeedOilChangeCount = () => {
    transport_api_get({
      school_id: this.state.school_id,
      action: "get_vehicules_need_oil_change_ids",
    }).then(res => {
      this.setState({
        vehicules_need_oil_change_ids: res.vehicules_need_oil_change_ids || []
      });
    })
  }
  getSubGroupsList = () => {
    get_list_schools_sub_groups({
      school: this.state.school_id
    }).then(res => {
      this.setState({
        sub_groups: res.sub_groups.filter(sg => !this.user.profile.is_assistant || sg.schools.filter(sc => sc.checked).length > 0).map(sg => {
          if(this.user.profile.is_assistant)
            sg.schools = sg.schools.filter(sc => sc.checked)
          return sg
        })
      });
    })
  }

  getScholarYears = () => {
    schools_scholar_years({
      scholar_years_only: true
    }).then(res => {
      var current_year = res.years.filter(y => y.id == this.state.scholar_year_id)[0],
        showExistantsButton = false;
      if(current_year && (current_year.next || (res.years.filter(y => y.previous).length > 0 && current_year.current)))
        showExistantsButton = true;
      
      
      this.setState({
        scholar_years: res.years,
        showExistantsButton: showExistantsButton
      });
    })
  }

  getClassesList = () => {
    var {school_id, scholar_year_id, classes} = this.state,
        pat = /^https?:\/\//i;
    var existing_classes_levels_ids = {};
    if (school_id) {
      var data = {
        action: "get_classes",
        school_id: school_id,
        scholar_year: scholar_year_id
      };
      school_classes_api(data).then(res => {
        this.setState({
          classes: res.classes.map(c => {
            if(!existing_classes_levels_ids[c.level && c.level.level_id || c.level_id]){
              existing_classes_levels_ids[c.level && c.level.level_id || c.level_id] = true;
            }
            c.full_image_url = (pat.test(c.full_image_url) || !c.full_image_url)?c.full_image_url:base_url + "/" +  c.full_image_url;
            return c;
          })
        }, () => this.getProfessorsList(Object.keys(existing_classes_levels_ids)));
      });
    } else {
      prof_classes_api({device_token: get('device_token')}).then(classes=> {
        this.setState({
          classes: classes
        }, () => this.getProfessorsList(Object.keys(existing_classes_levels_ids)));
        // get_instanceID_info(fcmToken).then(res => {
        //   storage.set('topics', JSON.stringify(res.rel.topics));
        // });
        // get_instanceID_info().then(res => {
        //   console.log(res)
        //   if(res.rel)
        //     set('topics', res.rel.topics);
        // });
      });
    }
  }
  getActivitiesClassesList = () => {
    var {school_id, scholar_year_id, activities_classes} = this.state,
        pat = /^https?:\/\//i;
    if (school_id) {
      var data = {
        action: "get_activities_classes",
        school_id: school_id,
        scholar_year: scholar_year_id
      };
      school_activities_classes_api(data).then(res => {
        this.setState({
          activities_classes:res.activities_classes.map(c => {
            c.full_image_url = (pat.test(c.full_image_url) || !c.full_image_url)?c.full_image_url:base_url + "/" +  c.full_image_url;
            return c;
          })
        }, this.update_professors);
      });
    } else {
      prof_activities_classes_api({}).then(activities_classes=> {
        this.setState({
          activities_classes: activities_classes
        }, this.update_professors);
      });
    }
  }

  getStudentsList = () => {
    const {school_id, scholar_year_id, students} = this.state;
    if(school_id){
      var data={
          action: "get_natif_students",
          page: 1,
          page_size: 50,
          school_id: school_id,
          scholar_year: scholar_year_id
      }
      schoolStudents(data).then(res=> {
          this.setStudents(res.students);
      });
    }else{
      profStudents({action: 'get_natif_students'}).then(res=> {
          this.setStudents(res.students.map(student=> {
              if(student.school_user){
                student.first_name = student.school_user.first_name;
                student.last_name = student.school_user.last_name;
              }
              return student;
          }));
      });
    }
  }

  setStudents(res_students){
    var {students} = this.state;
    var parents = [];
    res_students.forEach((student) =>{
        if(student.parent && student.parent.username != this.user.username)
            if(parents.filter(parent => parent.username == student.parent.username).length == 0 && student.parent){
                student.parent.classe = student.classe;
                parents.push(student.parent);
            }
        if(student.parent2 && student.parent2.username != this.user.username)
            if(parents.filter(parent => parent.username == student.parent2.username).length == 0 && student.parent2){
                student.parent2.classe = student.classe
                parents.push(student.parent2);    
            }
    });
    this.setState({
      students: res_students,
      parents: parents
    })
  }
  unshiftStudent = (student) => {
    var {students, parents} = this.state;
    if(student.parent && student.parent.username != this.user.username)
      if(parents.filter(parent => parent.username == student.parent.username).length == 0 && student.parent){
          student.parent.classe = student.classe;
          parents.push(student.parent);
      }
    if(student.parent2 && student.parent2.username != this.user.username)
      if(parents.filter(parent => parent.username == student.parent2.username).length == 0 && student.parent2){
          student.parent2.classe = student.classe
          parents.push(student.parent2);    
      }
    students.unshift(student);
    this.setState({
      students: students,
      parents: parents
    });
  }
  refreshMissingStudents = (missing_student, student) => {
    var {students, missing_students} = this.state;
    missing_students = missing_students.map(ms => {
      if(ms.id == missing_student.id){
        return missing_student;
      }
      return ms;
    })
    if(student){
      students.unshift(student);
    }
    this.setState({
      students: students,
      missing_students: missing_students,
    });
  }
  refreshAllSchoolFieldsExport = (school_fields_export) => {
    var {school_all_fields_export} = this.state;
    if(!school_all_fields_export[school_fields_export.data_type]){
      school_all_fields_export[school_fields_export.data_type] = {};
    }
    if(!school_all_fields_export[school_fields_export.data_type][school_fields_export.list_type]){
      school_all_fields_export[school_fields_export.data_type][school_fields_export.list_type] = {};
    }
    school_all_fields_export[school_fields_export.data_type][school_fields_export.list_type][school_fields_export.export_type] = school_fields_export;
    this.setState({
      school_all_fields_export: school_all_fields_export,
    });
  }
  refreshAllPredefinedSchoolFieldsExport = (school_fields_export) => {
    var {school_predefined_fields_export} = this.state;
    if(!school_predefined_fields_export[school_fields_export.data_type]){
      school_predefined_fields_export[school_fields_export.data_type] = {};
    }
    if(!school_predefined_fields_export[school_fields_export.data_type][school_fields_export.id]){
      school_predefined_fields_export[school_fields_export.data_type][school_fields_export.id] = {};
    }
    school_predefined_fields_export[school_fields_export.data_type][school_fields_export.id] = school_fields_export;
    this.setState({
      school_predefined_fields_export: school_predefined_fields_export,
    });
  }
  refreshAllSchoolTextbookSubjectsExport = (school_textbook_subjects_export) => {
    var {school_all_subjects_export} = this.state;
    if(!school_all_subjects_export[school_textbook_subjects_export.data_type]){
      school_all_subjects_export[school_textbook_subjects_export.data_type] = {};
    }
    school_all_subjects_export[school_textbook_subjects_export.data_type] = school_textbook_subjects_export;
    this.setState({
      school_all_subjects_export: school_all_subjects_export,
    });
  }
  unshiftProfessor = (professor) => {
    var {professors} = this.state;
    professors.unshift(professor);
    if(!professor.image && professor.image_url){
      professor.image = professor.image_url;
    }
    this.setState({
      professors: professors,
    });
  }
  updateStudentsTransportCircuit = (added_usernames, removed_usernames, circuit) => {
    var {students} = this.state;
    students = students.map(std => {
      if(added_usernames.indexOf(std.username) != -1){
        if(std.transport_circuit_ids.indexOf(circuit.id) == -1){
          std.transport_circuit_ids.push(circuit.id);
          std.transport_circuits.push(circuit);
        }
      }
      else if(removed_usernames.indexOf(std.username) != -1){
        std.transport_circuit_ids = std.transport_circuit_ids.filter(tci => tci != circuit.id);
        std.transport_circuits = std.transport_circuit_ids.filter(tc => tc.id != circuit.id);
      }
      return std;
    });
    this.setState({
      students: students
    });
  }

  handleViewAbsNotification = (viewed_absences_notifications) => {
    var data = {
      "action": "view_absences_notifications_count",
      "school_id": this.state.school_id,
      "viewed_absences_notifications": viewed_absences_notifications,
    };
    absences_post_api(data).then(res => {
      this.setState({
        absences_notifications_ids: this.state.absences_notifications_ids.filter(ani => viewed_absences_notifications.indexOf(ani) === -1),
      });
    });
  };
  handleViewIncidentNotification = (viewed_incidents_notifications) => {
    // var data = {
    //   "action": "view_incidents_notifications",
    //   "school_id": this.state.school_id,
    //   "scholar_year": this.state.scholar_year_id,
    //   "viewed_incidents_notifications": viewed_incidents_notifications,
    // };
    // behaviours_list_api_post(data).then(res => {
    //   this.setState({
    //     incidents_notifications_ids: this.state.incidents_notifications_ids.filter(ani => viewed_incidents_notifications.indexOf(ani) === -1),
    //   });
    // });
    var new_incidents_notifications_ids = this.state.incidents_notifications_ids.filter(ani => viewed_incidents_notifications.indexOf(ani) === -1);
    this.setState({
      incidents_notifications_ids: new_incidents_notifications_ids,
      students_notifications_ids: this.state.rdv_reports_notifications_ids.concat(new_incidents_notifications_ids).unique_item(),
    });
  };
  handleViewRdvReportNotification = (viewed_rdv_reports_notifications) => {
    // var data = {
    //   "action": "view_rdv_reports_notifications",
    //   "school_id": this.state.school_id,
    //   "scholar_year": this.state.scholar_year_id,
    //   "viewed_rdv_reports_notifications": viewed_rdv_reports_notifications,
    // };
    // reports_list_api_post(data).then(res => {
    //   this.setState({
    //     rdv_reports_notifications_ids: this.state.rdv_reports_notifications_ids.filter(ani => viewed_rdv_reports_notifications.indexOf(ani) === -1),
    //   });
    // });
    var new_rdv_reports_notifications_ids = this.state.rdv_reports_notifications_ids.filter(ani => viewed_rdv_reports_notifications.indexOf(ani) === -1);
    this.setState({
      rdv_reports_notifications_ids: new_rdv_reports_notifications_ids,
      students_notifications_ids: this.state.incidents_notifications_ids.concat(new_rdv_reports_notifications_ids).unique_item(),
    });
  };
  getAbsentProfessorsTodayIdsList = () => {
    const {school_id, scholar_year_id} = this.state;
    if(school_id){
        var data={
            "action": "get_absent_professors_today_ids",
            "page":1,
            "page_size":20,
            "school_id": school_id,
            "scholar_year": scholar_year_id
        }
        school_professors_list_api(data).then(res => {
          this.setState({
            absent_professors_today_ids: res.absent_professors_today_ids
          })
        });
    }
  };
  getNewRegisteredProfessorsIdsList = () => {
    const {school_id, scholar_year_id} = this.state;
    if(school_id){
        var data={
            "action": "get_new_registred_professors_ids",
            "page":1,
            "page_size":20,
            "school_id": school_id,
            "scholar_year": scholar_year_id
        }
        school_professors_list_api(data).then(res => {
          this.setState({
            new_registred_professors_ids: res.new_registred_professors_ids || []
          })
        });
    }
  };

  getNotifValidMail = () => {
    var data = {
      action: "get_notif_validated_messages",
      group_id: this.school.group_id 
    }

    school_mailings_api_list(data).then(res => {
      this.setState({
        valid_messages_notif_ids: res.messages_notif_ids
      })
    })
  }

  getNotifMail = () => {
    var data = {
      action: "get_notif_messages"
    }

    school_mailings_api_list(data).then(res => {
      this.setState({
        messages_notif_ids: res.messages_notif_ids
      })
    })
  }

  messagesViewed = () => {
    var data = {
      action: "set_notif_messages_viewed"
    }

    school_mailings_api_store(data)
  }

  validMessagesViewed = () => {
    var data = {
      action: "set_notif_validated_messages",
      mails_ids: this.state.valid_messages_notif_ids
    }

    school_mailings_api_store(data)
  }

  removeProfessorAbsenceNotif = (professor_id) => {
    var {school_id, scholar_year_id, absent_professors_today_ids} = this.state;
    var length_absent_professors_today_ids = absent_professors_today_ids.length;
    absent_professors_today_ids = absent_professors_today_ids.filter(api => api != professor_id);
    if(length_absent_professors_today_ids != absent_professors_today_ids.length){
      this.setState({absent_professors_today_ids: absent_professors_today_ids});
      if(school_id){
          var data={
              "action": "set_professor_absence_notif_viewed",
              "school_id": school_id,
              "scholar_year": scholar_year_id,
              "school_professor_id": professor_id,
          }
          school_professors_list_api(data).then(res => {
          });
      }
    }
  };
  absentProfessorsViewed = () => {
    const {school_id, scholar_year_id} = this.state;
    if(school_id){
        var data={
            "action": "absent_professors_notif_viewed",
            "page":1,
            "page_size":20,
            "school_id": school_id,
            "scholar_year": scholar_year_id
        }
        school_professors_list_api(data).then(res => {
        });
    }
  };

  update_professors = () => {
    if(true || (this.state.classes.length > 0 || this.state.activities_classes.length > 0) && this.state.professors.length > 0){
      this.setState({
        professors: this.state.professors.map(pr => {
          pr.classes = pr.classes.map(classe_id => {
            let cl = this.state.classes.find(c => c.classe_id == classe_id);
            if(cl)
              return {
                classe_id: classe_id,
                name: cl.name,
                level: {
                  level_id: cl.level.level_id,
                  name: cl.level.name
                }
              }
            else
              return classe_id
          });

          pr.activities_classes = pr.activities_classes.map(id => {
            let cl = this.state.activities_classes.find(c => c.id == id);
            if(cl)
              return {
                id: id,
                name: cl.name
              }
            else
              return id
          });
          
          if(this.get_local_levels().length > 0){
            pr.levels = pr.levels.filter(lvl_id => this.get_local_levels().findIndex(l => l.level_id == lvl_id) >= 0).map(lvl_id => {
              let lvl = this.get_local_levels().find(l => l.level_id == lvl_id)
              return {
                name: lvl.name,
                level_id: lvl.level_id,
                group_id: lvl.group_id,
                subjects: lvl.subjects.map(s => ({
                  subject_id: s.subject_id,
                  name: s.name
                }))
              }
            })
            pr.subjects = pr.subjects.map(sub_id => {
              let curr_lvl = pr.levels.find(lvl => lvl.subjects.findIndex(s => s.subject_id == sub_id) >= 0),
                sub = curr_lvl?curr_lvl.subjects.find(s => s.subject_id == sub_id):false;
              if(sub)
                return {
                  subject_id: sub.subject_id,
                  name: sub.name
                }
              else return sub_id
            })
          }


          if(!pr.image && pr.image_url){
            pr.image = pr.image_url;
          }

          return pr;
        })
      })
    }
  }

  getProfessorsList = (existing_classes_levels_ids) => {
    const {school_id, scholar_year_id, professors} = this.state;
    if(this.school){
        let permission = new Permission();
        var data = {
          action: "get_natif_professors",
          school_id: this.school.id,
          scholar_year_id: scholar_year_id,
          level_ids: this.can_update_level_classe_for_all_cycles || permission.can("update_level_classe_for_all_cycles", "professors") ? this.school_levels_for_assistant.map(l => "'" + l.level_id + "'").join(',') : this.levels.filter(l => !permission.hasSection('cycle_' + l.group_id) || permission.hasLevel('cycle_' + l.group_id, 'level_' + l.level_id)).map(l => "'" + l.level_id + "'").join(',')
        }
        
        school_professors_list_api2(data).then(res => {
          var levels_ids_ = this.levels.filter(l => !permission.hasSection('cycle_' + l.group_id) || permission.hasLevel('cycle_' + l.group_id, 'level_' + l.level_id)).map(l => l.level_id);

          this.setState({
            professors: res.professors.map(pr => {
              if(this.school && this.user.profile.is_assistant && (this.can_update_level_classe_for_all_cycles || permission.can("update_level_classe_for_all_cycles", "professors"))){
                if(levels_ids_.some(item => pr.levels.includes(item))){

                }
                else{
                  pr.edit_only_level_classe = true;
                }
              }
              return pr;
            })
          }, () => {
            this.update_professors();
            if(this.school && this.can_update_level_classe_for_all_cycles || permission.can("update_level_classe_for_all_cycles", "professors")){
              var pat = /^https?:\/\//i;
              var data_ = {
                action: "get_classes",
                school_id: school_id,
                scholar_year: scholar_year_id,
                levels_ids_to_exclude: existing_classes_levels_ids,
              };
              school_classes_store_api(data_).then(res => {
                this.setState({
                  assistant_other_classes: res.classes.map(c => {
                    c.full_image_url = (pat.test(c.full_image_url) || !c.full_image_url)?c.full_image_url:base_url + "/" +  c.full_image_url;
                    return c;
                  })
                });
              });
            }
          })
        });
    }
    else{
        var data={
            action: "get_professors_contacts_list",
            include_all: 1
        }
        professor_professors_list_api(data).then(res => {
          this.setState({
            professors: (res.professors || []).map(pr => {
              if(!pr.image && pr.image_url){
                pr.image = pr.image_url;
              }
              return pr;
            })
          })
        });
    }
  };
  getJournals = ()=>{
    const {school_id, scholar_year_id, professors} = this.state;
    var user = get('session_user')
    if(!school_id){
      var data1 = {
        action: "get_journals",
        /*page_size: PAGE_SIZE,
        page: 1*/
        day_week: moment().startOf("week").add(3, "days").format("YYYY-MM-DD"),
      };
      instance
      .get(`/journal_handler`, {
        headers: {
          Authorization: "Bearer " + user.token
        },
        params: data1
      })
      .then(response => {
        this.setState({
          data_loaded:true,
          seances:response.data.journals.concat([] || response.data.sanc_jou).concat(response.data.deleted_jouranl),
        })
      
      }).catch(err => console.log("error", err));
    }
  }

  handleSwitchMenuGlobal = () => {
    this.setState({
      menuGlobal: !this.state.menuGlobal
    })
  }

  refreshStudentsToChangeLevels = () => {

    const {school_id, scholar_year_id} = this.state;
    if(school_id){
      var data = {};
      data.school_id = school_id;
      data.scholar_year = scholar_year_id;
      data.action = "get_natif_students_to_change_levels";
      data.only_ids = "true";
      schoolStudents(data).then(res => {
        this.setState({
          students_to_change_levels_ids: res.students_to_change_levels_ids || []
        })
      });
    }
  }
  refreshStudentsMissings = () => {

    const {school_id, scholar_year_id} = this.state;
    if(school_id){
      var data = {};
      data.school_id = school_id;
      data.scholar_year_id = scholar_year_id;
      data.action = "get_natif_missing_students";
      missing_students_api_get(data).then(res => {
        this.setState({
          missing_students: res.missing_students || []
        })
      });
    }
  }

  render() {
    const { Layout, Component } = this.props;
    if(this.props.path == "/school/list" || this.props.path == "/school/program/list"){
      return <Component {...this.props}/>
    }
    else{
      let permission = new Permission();
      this.professors_ = [].concat(this.state.professors);
      if(this.school && this.user.profile.is_assistant && !(this.props.pagetype == "professors" && (this.can_update_level_classe_for_all_cycles || permission.can("update_level_classe_for_all_cycles", "professors")))){
        var levels_ids = this.levels.filter(l => !permission.hasSection('cycle_' + l.group_id) || permission.hasLevel('cycle_' + l.group_id, 'level_' + l.level_id)).map(l => l.level_id);
        this.professors_ = [].concat(this.state.professors.filter(pr => {
          return levels_ids.some(item => pr.levels.map(l => l.level_id || l).includes(item));
        }));
      }
      return (
        <Layout 
          handleSwitchMenuGlobal={this.handleSwitchMenuGlobal}
          menuGlobal={this.state.menuGlobal}
          ws={this.ws}
        >
          <Component
            {...this.props}
            // extra_props={this.state.extra_props}
            global_data={{...this.state, professors: this.professors_}}
            unshiftProfessor={this.unshiftProfessor}
            handleUpdateProfessor={pr => {
              this.setState({
                professors : this.state.professors.map(p => {
                  if(p.id == pr.id)
                    return pr;
                  return p;
                })
              })
            }}
            unshiftStudent={this.unshiftStudent}
            refreshMissingStudents={this.refreshMissingStudents}
            refreshAllSchoolFieldsExport={this.refreshAllSchoolFieldsExport}
            refreshAllPredefinedSchoolFieldsExport={this.refreshAllPredefinedSchoolFieldsExport}
            refreshAllSchoolTextbookSubjectsExport={this.refreshAllSchoolTextbookSubjectsExport}
            handleViewAbsNotification={this.handleViewAbsNotification}
            handleViewIncidentNotification={this.handleViewIncidentNotification}
            handleViewRdvReportNotification={this.handleViewRdvReportNotification}
            refreshAbsentProfessorsTodayIds={this.getAbsentProfessorsTodayIdsList}
            refreshNewProfessorsIds={this.getNewRegisteredProfessorsIdsList}
            removeProfessorAbsenceNotif={this.removeProfessorAbsenceNotif}
            absentProfessorsTodayNotifViewed={() => {
              if(this.state.absent_professors_today_ids.length){
                this.absentProfessorsViewed();
                setTimeout(() => {
                  this.setState({absent_professors_today_ids: []});
                }, 100);
              }
            }}
            newRegistrationProfessorsViewed={(viewed_id, school_professor) => {
              var {professors} = this.state;
              if(this.state.new_registred_professors_ids.length){
                setTimeout(() => {
                  if(school_professor){
                    professors.unshift(school_professor);
                  }
                  this.setState({
                    new_registred_professors_ids: this.state.new_registred_professors_ids.filter(nri => nri != viewed_id),
                    professors: professors,
                  });
                }, 100);
              }
            }}
            refreshStudentsToChangeLevels={() => {
              this.refreshStudentsToChangeLevels()
            }}
            messagesViewed={() => {
              if(this.state.messages_notif_ids.length > 0){
                this.messagesViewed();
                this.setState({messages_notif_ids: []});
              }
            }}
            validMessagesViewed={() => {
              
              if(this.state.valid_messages_notif_ids.length > 0){
                this.validMessagesViewed();
                this.setState({valid_messages_notif_ids: []});
              }
            }}
            updateStudentsTransportCircuit={this.updateStudentsTransportCircuit}
            handleSetClasses={(classes) => this.setState({classes:classes})} 
            handleSetActivitiesClasses={(activities_classes) => this.setState({activities_classes:activities_classes})} 
            refreshVehiculesNeedOilChangeCount={() => this.getVehiculesNeedOilChangeCount()} 
            ws={this.ws}
          />
        </Layout>
      );
    }
  }
};

const mapState2Props = state => ({ session: state.session });
export default withRouter(connect(mapState2Props)(LayoutSchoolSystem));
